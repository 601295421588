var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewDashBoard project-route-index"},[_c('div',{staticClass:"container"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{staticClass:"white-row"},[_c('div',{staticClass:"show-project-portal-listing"},[_c('div',{staticClass:"general-filters"},[_c('div',{staticClass:"top-area-projects-index"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 col-lg-12 col-md-12 col-sm-12"},[_c('h1',{staticClass:"global-style-heading"},[_vm._v(" Projects "),_c('a',{staticClass:"refreshData",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.refreshProjectTable.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-redo-alt"})])])])])])]),_c('div',{staticClass:"horizontal-row"},[_c('hr')]),_c('section',{staticClass:"client-projects"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.data_loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{key:"2",staticClass:"project-list-wrapper"},[(
                    typeof _vm.projectsList.data !== 'undefined' &&
                      Object.keys(_vm.projectsList.data).length
                  )?_c('div',{staticClass:"row"},_vm._l((_vm.projectsList.data),function(project,project_index){return _c('div',{key:project_index,staticClass:"col-xl-3 col-lg-4 col-md-6 col-sm-12"},[_c('div',{staticClass:"wrap-box"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"project-details"},[_c('p',{staticClass:"project-id"},[_vm._v("ID: "+_vm._s(project.id))]),_c('div',{staticClass:"project-listing"},[_c('router-link',{staticClass:"eclipse-para global-para-link2",attrs:{"to":{
                                path: '/projects/show/' + project.id
                              },"tag":"p"}},[_vm._v(" "+_vm._s(project.project_name)+" ")])],1),_c('p',{staticClass:"project-status"},[_vm._v(" Brand: "),_c('b',[_vm._v(_vm._s(project.project_brand != null ? project.project_brand.name : "N/A"))])]),_c('p',{staticClass:"project-status"},[_vm._v(" Status: "),_c('b',[_vm._v(_vm._s(project.project_status.status_name))])]),_c('p',{staticClass:"project-status"},[_vm._v(" Category: "),_c('b',[_vm._v(_vm._s(project.project_category != null ? project.project_category.category_name : "N/A"))])])]),_c('div',{staticClass:"footer-box"},[_c('div',{staticClass:"relv"},[_c('div',{staticClass:"usr-role"},[_c('p',[_vm._v("Account Managers:")])]),(project.project_account_manager.length)?_c('div',{staticClass:"usr-role-name-glb text-right"},_vm._l((project.project_account_manager),function(am,am_index){return _c('div',{key:am_index,class:_vm.avatarClass(
                                    project.project_account_manager,
                                    am_index
                                  )},[(am.name != null)?_c('avatar',{attrs:{"inline":true,"username":am.name}}):_vm._e(),_vm._v(" "+_vm._s(project.project_account_manager_total_count > 3 && am_index + 1 > 2 ? "+" + project.project_account_manager_remaining_count : "")+" ")],1)}),0):_c('div',{staticClass:"usr-role-name-glb text-right right-pd-zero"},[_vm._v(" N/A ")])]),_c('div',{staticClass:"relv"},[_c('div',{staticClass:"usr-role"},[_c('p',[_vm._v("Clients:")])]),(project.project_clients.length)?_c('div',{staticClass:"usr-role-name-glb text-right"},_vm._l((project.project_clients),function(client,client_index){return _c('div',{key:client_index,class:_vm.avatarClass(
                                    project.project_clients,
                                    client_index
                                  )},[(client.name != null)?_c('avatar',{attrs:{"inline":true,"username":client.name}}):_vm._e(),_vm._v(" "+_vm._s(project.project_clients_total_count > 3 && client_index + 1 > 2 ? "+" + project.project_clients_remaining_count : "")+" ")],1)}),0):_c('div',{staticClass:"usr-role-name-glb text-right right-pd-zero"},[_vm._v(" N/A ")])])])])])])}),0):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('no-record-found')],1)])])])],1),_c('section',{staticClass:"paginations-section"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('pagination',{staticClass:"pagination justify-content-center",attrs:{"data":_vm.projectsList,"limit":1},on:{"pagination-change-page":_vm.getResults}})],1)])])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }