var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewDashBoard project-route-index"},[_c('div',{staticClass:"container"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{staticClass:"white-row"},[_c('div',{staticClass:"show-project-portal-listing"},[_c('div',{staticClass:"general-filters"},[_c('div',{staticClass:"top-area-projects-index"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-9 col-lg-9 col-md-9 col-sm-6"},[_c('h1',{staticClass:"global-style-heading"},[_vm._v(" Projects "),_c('a',{staticClass:"refreshData",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.refreshProjectTable.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-redo-alt"})])])]),_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-6 col-sm-6"},[_c('div',{staticClass:"form-actions"},[_c('router-link',{staticClass:"btn btn-primary default-global-btn",attrs:{"type":"submit","id":"save-form","to":{
                        name: 'projects.create'
                      },"tag":"button"}},[_c('i',{staticClass:"fas fa-plus-circle"}),_vm._v(" Add Project ")])],1)])])]),_c('div',{staticClass:"bottom-form-filters"},[_c('form',{staticClass:"create-client-form",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-6 col-sm-6"},[_c('div',{},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v(" ")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.search),expression:"formData.search"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search..."},domProps:{"value":(_vm.formData.search)},on:{"keyup":function($event){return _vm.getResults(null, 'search')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "search", $event.target.value)}}}),_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"}},[_c('i',{staticClass:"fa fa-search"})])])])])])]),_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-6 col-sm-6"},[_c('div',{},[_c('div',{staticClass:"form-group full-width-md"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v(" ")]),_c('Select2',{attrs:{"options":_vm.project_clients,"settings":{
                            placeholder: 'Project Clients',
                            allowClear: true
                          }},on:{"change":function($event){return _vm.getResults(null)}},model:{value:(_vm.formData.client_id),callback:function ($$v) {_vm.$set(_vm.formData, "client_id", $$v)},expression:"formData.client_id"}})],1)])]),_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-6 col-sm-6"},[_c('div',{},[_c('div',{staticClass:"form-group full-width-md"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v(" ")]),_c('Select2',{attrs:{"options":_vm.project_status_category,"settings":{
                            placeholder: 'Projects Status',
                            allowClear: true
                          }},on:{"change":function($event){return _vm.getResults(null)}},model:{value:(_vm.formData.status_id),callback:function ($$v) {_vm.$set(_vm.formData, "status_id", $$v)},expression:"formData.status_id"}})],1)])]),_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-6 col-sm-6"},[_c('div',{},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v(" ")]),_c('Select2',{attrs:{"options":_vm.project_categoreies,"settings":{
                            placeholder: 'Project Category',
                            allowClear: true
                          }},on:{"change":function($event){return _vm.getResults(null)}},model:{value:(_vm.formData.category_id),callback:function ($$v) {_vm.$set(_vm.formData, "category_id", $$v)},expression:"formData.category_id"}})],1)])]),_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-6 col-sm-6"},[_c('div',{},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"brand_id"}},[_vm._v(" ")]),_c('Select2',{attrs:{"options":_vm.project_brands,"settings":{
                            placeholder: 'Project Brands',
                            allowClear: true
                          }},on:{"change":function($event){return _vm.getResults(null)}},model:{value:(_vm.formData.brand_id),callback:function ($$v) {_vm.$set(_vm.formData, "brand_id", $$v)},expression:"formData.brand_id"}})],1)])]),_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-6 col-sm-6"},[_c('div',{},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v(" ")]),_c('Select2',{attrs:{"options":_vm.user_gender,"settings":{
                            placeholder: 'Sort Order',
                            allowClear: true
                          }},on:{"change":function($event){return _vm.getResults(null)}},model:{value:(_vm.formData.sort_order),callback:function ($$v) {_vm.$set(_vm.formData, "sort_order", $$v)},expression:"formData.sort_order"}})],1)])]),_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-6 col-sm-6"},[_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn btn-primary default-global-btn",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.ResetValues($event)}}},[_vm._v(" Reset ")])])])])])])]),_c('div',{staticClass:"horizontal-row"},[_c('hr')]),_c('section',{staticClass:"client-projects"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.data_loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{key:"2",staticClass:"project-list-wrapper"},[(
                    typeof _vm.projectsList.data !== 'undefined' &&
                      Object.keys(_vm.projectsList.data).length
                  )?_c('div',{staticClass:"row"},_vm._l((_vm.projectsList.data),function(project,project_index){return _c('div',{key:project_index,staticClass:"col-xl-3 col-lg-4 col-md-6 col-sm-12"},[_c('div',{staticClass:"wrap-box"},[_c('div',{staticClass:"header-top"},[_c('dropdown',[_c('template',{slot:"icon"},[_c('i',{staticClass:"fas fa-ellipsis-v"})]),_c('template',{slot:"body"},[_c('ul',{staticClass:"list-group"},[_c('li',{staticClass:"list-group-item"},[_c('router-link',{attrs:{"to":{
                                    path: '/projects/edit/' + project.id
                                  },"tag":"a"}},[_vm._v(" Edit ")])],1)])])],2)],1),_c('div',{staticClass:"box"},[_c('div',{staticClass:"project-details"},[_c('p',{staticClass:"project-id"},[_vm._v("ID: "+_vm._s(project.id))]),_c('div',{staticClass:"project-listing"},[_c('router-link',{staticClass:"eclipse-para color-blk customize-global-link gl-lk-blk",attrs:{"to":{
                                path: '/projects/show/' + project.id
                              },"tag":"a"}},[_vm._v(" "+_vm._s(project.project_name)+" ")])],1),_c('p',{staticClass:"project-status"},[_vm._v(" Brand: "),_c('b',[_vm._v(_vm._s(project.project_brand != null ? project.project_brand.name : "N/A"))])]),_c('p',{staticClass:"project-status"},[_vm._v(" Status: "),_c('b',[_vm._v(_vm._s(project.project_status.status_name))])]),_c('p',{staticClass:"project-status"},[_vm._v(" Category: "),_c('b',[_vm._v(_vm._s(project.project_category != null ? project.project_category.category_name : "N/A"))])])]),_c('div',{staticClass:"footer-box"},[_c('div',{staticClass:"relv"},[_c('div',{staticClass:"usr-role"},[_c('p',[_vm._v("Account Managers:")])]),(project.project_account_manager.length)?_c('div',{staticClass:"usr-role-name-glb text-right"},_vm._l((project.project_account_manager),function(am,am_index){return _c('div',{key:am_index,class:_vm.avatarClass(
                                    project.project_account_manager,
                                    am_index
                                  )},[(am.name != null)?_c('a',{attrs:{"title":am.name}},[(am.name != null)?_c('avatar',{attrs:{"inline":true,"username":am.name}}):_vm._e()],1):_vm._e(),_vm._v(" "+_vm._s(project.project_account_manager_total_count > 3 && am_index + 1 > 2 ? "+" + project.project_account_manager_remaining_count : "")+" ")])}),0):_c('div',{staticClass:"usr-role-name-glb text-right right-pd-zero"},[_vm._v(" N/A ")])]),_c('div',{staticClass:"relv"},[_c('div',{staticClass:"usr-role"},[_c('p',[_vm._v("Clients:")])]),(project.project_clients.length)?_c('div',{staticClass:"usr-role-name-glb text-right"},_vm._l((project.project_clients),function(client,client_index){return _c('div',{key:client_index,class:_vm.avatarClass(
                                    project.project_clients,
                                    client_index
                                  )},[(client_index < 3)?[(client.name != null)?_c('a',{attrs:{"title":client.name}},[(client.name != null)?_c('avatar',{attrs:{"inline":true,"username":client.name}}):_vm._e()],1):_vm._e(),_vm._v(" "+_vm._s(project.project_clients_total_count > 3 && client_index + 1 > 2 ? "+" + project.project_clients_remaining_count : "")+" ")]:_vm._e()],2)}),0):_c('div',{staticClass:"usr-role-name-glb text-right right-pd-zero"},[_vm._v(" N/A ")])])])])])])}),0):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('no-record-found')],1)])])])],1),_c('section',{staticClass:"paginations-section"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('pagination',{staticClass:"pagination justify-content-center",attrs:{"data":_vm.projectsList,"limit":1},on:{"pagination-change-page":_vm.getResults}})],1)])])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }